// Generated by Framer (c93245f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["mEAGsD8He", "MxJ5iFW7Z"];

const serializationHash = "framer-h45ZJ"

const variantClassNames = {mEAGsD8He: "framer-v-1j9l032", MxJ5iFW7Z: "framer-v-k31gsc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Primary - Light": "MxJ5iFW7Z", Default: "mEAGsD8He"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "mEAGsD8He"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "mEAGsD8He", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "MxJ5iFW7Z") return false
return true
}

const isDisplayed1 = () => {
if (baseVariant === "MxJ5iFW7Z") return true
return false
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 29, intrinsicWidth: 137, pixelHeight: 29, pixelWidth: 137, src: "https://framerusercontent.com/images/E5myaVUgrAN9vkh3485pi8gmFg8.svg"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1j9l032", className, classNames)} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"mEAGsD8He"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({MxJ5iFW7Z: {"data-framer-name": "Primary - Light"}}, baseVariant, gestureVariant)}>{isDisplayed() && (<Image background={{alt: "", fit: "fit", intrinsicHeight: 120, intrinsicWidth: 300, pixelHeight: 120, pixelWidth: 300, positionX: "center", positionY: "center", src: "https://framerusercontent.com/images/tqYaiK3rstTAm3LWx4SMDAsiwec.svg?lossless=1"}} className={"framer-17tlsrn"} data-framer-name={"Black"} layoutDependency={layoutDependency} layoutId={"Cnq7WQSPs"}/>)}{isDisplayed1() && (<Image background={{alt: "", fit: "fit", intrinsicHeight: 120, intrinsicWidth: 300, pixelHeight: 120, pixelWidth: 300, positionX: "center", positionY: "center", src: "https://framerusercontent.com/images/Xb6EdUEclViFumrbtHpcjn72Ks.svg?lossless=1"}} className={"framer-1lh6ca2"} data-framer-name={"Light"} layoutDependency={layoutDependency} layoutId={"F3kRo4TBD"}/>)}</motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-h45ZJ.framer-oqf1wf, .framer-h45ZJ .framer-oqf1wf { display: block; }", ".framer-h45ZJ.framer-1j9l032 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-h45ZJ .framer-17tlsrn, .framer-h45ZJ .framer-1lh6ca2 { flex: none; height: 58px; overflow: hidden; position: relative; width: 140px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-h45ZJ.framer-1j9l032 { gap: 0px; } .framer-h45ZJ.framer-1j9l032 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-h45ZJ.framer-1j9l032 > :first-child { margin-left: 0px; } .framer-h45ZJ.framer-1j9l032 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 58
 * @framerIntrinsicWidth 140
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"MxJ5iFW7Z":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerrt3X2BiTn: React.ComponentType<Props> = withCSS(Component, css, "framer-h45ZJ") as typeof Component;
export default Framerrt3X2BiTn;

Framerrt3X2BiTn.displayName = "Brand Logo";

Framerrt3X2BiTn.defaultProps = {height: 58, width: 140};

addPropertyControls(Framerrt3X2BiTn, {variant: {options: ["mEAGsD8He", "MxJ5iFW7Z"], optionTitles: ["Default", "Primary - Light"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerrt3X2BiTn, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})